import { assignTextStyle, variantProvider, AppForms, React } from '@/app'
import { ActionIcon, ActivityIndicator, Button, Text, View } from '@/components'
import { APIClient } from '@/services'
import { DisputeThread } from '@/types'
import {
  useForm,
  useMemo,
  useState,
  usePrevious,
  onUpdate,
} from '@codeleap/common'
import { TextInputProps } from '@codeleap/web'
import { BaseToolbar } from './BaseToolbar'

type ToolBarProps = Omit<TextInputProps, 'id' | 'debugName'> & {
  onSend: (content: string) => Promise<void>
  thread?: DisputeThread['id']
  aiMassageDisabled?: boolean
}

export function MediatorToolbar({
  onSend,
  aiMassageDisabled = false,
  thread,
}: ToolBarProps) {
  const form = useForm(AppForms.chat)
  const prevThread = usePrevious(thread)
  const [hasFocused, setHasFocused] = useState(false)
  const [_isLoading, setIsLoading] = useState(false)

  const { hasMessage, message, ...query } =
    APIClient.Threads.useMediatorMessages(thread, {
      enabled: !aiMassageDisabled,
    })

  const hasMessageAndNotFocused = !hasFocused && hasMessage
  const isMessageEmpty = !form.values.message?.trim()
  const isLoading = aiMassageDisabled ? false : _isLoading || query.isLoading

  onUpdate(() => {
    setIsLoading(false)
    form.setFieldValue('message', '')
    if (prevThread !== thread) query.refetch()
  }, [thread])

  onUpdate(() => {
    if (query.errorUpdateCount > 0 || query.error) setIsLoading(false)
  }, [query.errorUpdateCount, query.error])

  onUpdate(() => {
    if (hasMessage && query.data.thread === thread) {
      form.setFieldValue('message', message)
    } else form.reset()
    setHasFocused(false)
    setIsLoading(false)
  }, [query.dataUpdatedAt])

  async function handleSend() {
    const _message = form.values.message
    form.setFieldValue('message', '')
    if (!_message.trim()) return
    await onSend(_message)
  }

  function getNewMessage() {
    query.refetch()
    setIsLoading(true)
  }

  const { leftIcon, rightIcon } = useMemo(() => {
    let leftIcon = null
    if (hasMessageAndNotFocused) {
      leftIcon = {
        name: 'stars',
        styles: { icon: styles.aiIcon, touchableWrapper: styles.aiTouchable },
      }
    }
    if (isLoading) {
      leftIcon = (
        <ActivityIndicator
          variants={['small']}
          debugName="loading ai message"
        />
      )
    }

    const rightIcon = isLoading ? null : (
      <View css={styles.rightWrapper}>
        {isMessageEmpty ? (
          <>
            <Button
              disabled={aiMassageDisabled}
              variants={['minimal', 'padding:0', 'iconSize:2']}
              text="Generate message"
              icon="stars"
              debugName="Load new message for mediator"
              onPress={getNewMessage}
              responsiveVariants={{ small: ['hideText'] }}
            />
            <View css={styles.separator} />
          </>
        ) : null}
        <ActionIcon
          variants={['alignSelfCenter', 'minimal', 'normalize']}
          debugName="Send message"
          onPress={handleSend}
          name="send"
          disabled={isMessageEmpty || !hasFocused}
        />
      </View>
    )

    return { leftIcon, rightIcon }
  }, [hasMessageAndNotFocused, form.values, hasFocused, isLoading])

  return (
    <View css={styles.wrapper}>
      <BaseToolbar
        onSend={handleSend}
        form={form}
        placeholder={isLoading ? 'Generating message' : 'Send a message'}
        leftIcon={leftIcon}
        rightIcon={rightIcon as unknown as TextInputProps['rightIcon']}
        onFocus={() => setHasFocused(true)}
        variants={[hasMessageAndNotFocused ? 'aiMessage' : ''] as any}
        disabled={isLoading}
      />
      {hasMessage ? (
        <Text
          css={styles.aiText}
          text="Written by AI. Edit the message before send it."
        />
      ) : null}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    wrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(1),
      zIndex: 10,
      ...theme.spacing.paddingTop(0.25),
      backgroundColor: theme.colors.neutral1,
      ...theme.presets.bottom,
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
    },

    separator: {
      width: 1,
      ...theme.presets.flex,
      backgroundColor: theme.colors.neutral4,

      [theme.media.down('small')]: {
        display: 'none',
      },
    },

    rightWrapper: {
      ...theme.presets.row,
      ...theme.spacing.gap(2),
      ...theme.presets.fullHeight,

      [theme.media.down('small')]: {
        flexDirection: 'row-reverse',
      },
    },

    aiText: {
      ...assignTextStyle('p4')(theme).text,
      lineHeight: '16px',
    },
    aiIcon: {
      color: theme.colors.primary4,
    },
    aiTouchable: {
      width: 'unset',
      height: 'unset',
      backgroundColor: 'unset',
      ...theme.spacing.marginRight(1),
    },
  }),
  true
)
